<template>
  <div class="nutriSupp">
    <h1>NutriSupp Page</h1>
    <p>NutriSupp information will be displayed here.</p>
  </div>
</template>

<script>
export default {
  name: 'NutriSuppPage'
};
</script>

<style>
.nutriSupp {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: yellow;
}
</style>
